#root {
  /* overflow-x: hidden; */
  position: relative;
}

section {
  overflow: hidden;
  /* @media screen and (max-width: 766px) {
    min-width: 360px;
  } */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toastui-editor-contents h1 {
  line-height: normal !important;
  border-bottom: none !important;
  margin: 0 !important;
  padding-bottom: 0 !important;
}

.toastui-editor-contents h2 {
  line-height: normal !important;
  border-bottom: none !important;
  margin: 0 !important;
  padding-bottom: 0 !important;
}

.toastui-editor-contents h3 {
  line-height: normal !important;
  margin: 0 !important;
}

.toastui-editor-contents h4 {
  line-height: normal !important;
  margin: 0 !important;
}

.toastui-editor-contents h5 {
  line-height: normal !important;
  margin: 0 !important;
}

.toastui-editor-contents h6 {
  line-height: normal !important;
  margin: 0 !important;
}

@keyframes typing-cursor {
  from {
    border-right: 3px solid #fff;
  }
  to {
    border-right: 3px solid #0000;
  }
}

@font-face {
  font-family: "SB_Aggro_L";
  src: url("../public/font/SB_Aggro_OTF_L.otf") format("opentype");
}

@font-face {
  font-family: "SB_Aggro_M";
  src: url("../public/font/SB_Aggro_OTF_M.otf") format("opentype");
}

@font-face {
  font-family: "SB_Aggro_B";
  src: url("../public/font/SB_Aggro_OTF_B.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard-Variable-R";
  font-weight: 400;
  src: url("../public/font/PretendardVariable.ttf");
}

@font-face {
  font-family: "Pretendard-Variable-M";
  font-weight: 600;
  src: url("../public/font/PretendardVariable.ttf");
}

@font-face {
  font-family: "Pretendard-Variable-B";
  font-weight: 700;
  src: url("../public/font/PretendardVariable.ttf");
}

reach-portal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  [data-rsbs-scroll] {
    overflow: visible;
  }
  [data-rsbs-content] {
    overflow: visible;
  }
  [data-rsbs-backdrop] {
    max-width: 100vw;
  }
  [data-rsbs-overlay] {
    max-width: 100vw;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background: rgba(50, 47, 53, 1) !important;
  background-color: rgba(50, 47, 53, 1);
  font-size: 14px;
  font-family: Pretendard-Variable-R;
  color: rgba(245, 239, 247, 1) !important;
  @media screen and (min-width: 360px) {
    font-size: calc(7px + (100vw * 2 - 360px) * (7 / 360));
  }

  @media screen and (min-width: 767px) {
    font-size: calc(20px + (100vw - 767px) * (10 / (1440 - 767)));
  }

  @media screen and (min-width: 1440px) {
    font-size: 20px;
  }
}

.Toastify__close-button {
  align-self: center !important;
}
